// Generated by Framer (5ac62b5)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, Link, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {ru8Nz1Lpn: {hover: true, pressed: true}};

const cycleOrder = ["ru8Nz1Lpn"];

const serializationHash = "framer-Ybuii"

const variantClassNames = {ru8Nz1Lpn: "framer-v-9mcrf9"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, image, width, ...props}) => { return {...props, pbrU38Tu3: image ?? props.pbrU38Tu3 ?? {src: "https://framerusercontent.com/images/YCLJJn6g2ELAeTfIx44br9eGcM0.png?scale-down-to=512", srcSet: "https://framerusercontent.com/images/YCLJJn6g2ELAeTfIx44br9eGcM0.png?scale-down-to=512 512w, https://framerusercontent.com/images/YCLJJn6g2ELAeTfIx44br9eGcM0.png 564w"}} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;image?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, pbrU38Tu3, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "ru8Nz1Lpn", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-Ybuii", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><Link href={{webPageId: "OXQ86S9jc"}} openInNewTab={false}><motion.a {...restProps} className={`${cx("framer-9mcrf9", className)} framer-1igzqk0`} data-framer-name={"Logo"} layoutDependency={layoutDependency} layoutId={"ru8Nz1Lpn"} ref={ref ?? ref1} style={{opacity: 1, ...style}} variants={{"ru8Nz1Lpn-hover": {opacity: 0.5}, "ru8Nz1Lpn-pressed": {opacity: 0.5}}} {...addPropertyOverrides({"ru8Nz1Lpn-hover": {"data-framer-name": undefined}, "ru8Nz1Lpn-pressed": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><Image background={{alt: "", fit: "stretch", sizes: "141px", ...toResponsiveImage(pbrU38Tu3)}} className={"framer-fd5hf8"} data-framer-name={"SG Logo Text Only 1"} layoutDependency={layoutDependency} layoutId={"B3NvVpMeU"}/></motion.a></Link></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-Ybuii [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Ybuii .framer-1igzqk0 { display: block; }", ".framer-Ybuii .framer-9mcrf9 { height: 52px; overflow: visible; position: relative; text-decoration: none; width: 141px; }", ".framer-Ybuii .framer-fd5hf8 { flex: none; height: 36px; left: 0px; position: absolute; top: calc(50.00000000000002% - 36px / 2); width: 141px; }", ".framer-Ybuii .framer-v-9mcrf9 .framer-9mcrf9 { cursor: pointer; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 52
 * @framerIntrinsicWidth 141
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"tAXzyOS6F":{"layout":["fixed","fixed"]},"OSMbho0Bc":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"pbrU38Tu3":"image"}
 * @framerImmutableVariables true
 */
const FramerepL4PoOJz: React.ComponentType<Props> = withCSS(Component, css, "framer-Ybuii") as typeof Component;
export default FramerepL4PoOJz;

FramerepL4PoOJz.displayName = "Logo";

FramerepL4PoOJz.defaultProps = {height: 52, width: 141};

addPropertyControls(FramerepL4PoOJz, {pbrU38Tu3: {__defaultAssetReference: "data:framer/asset-reference,YCLJJn6g2ELAeTfIx44br9eGcM0.png?originalFilename=SG+Logo+Text+Only+1.png&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerepL4PoOJz, [])